import { Model } from "pinia-orm";

export default class MessageStatus extends Model {
  static entity = "messageStatuses";
  static fields() {
    return {
      id: this.string(null),
      messageId: this.string(null),
      read: this.string(null),
      received: this.string(null),
      receiverId: this.string(null),
    };
  }
}
