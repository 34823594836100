import { Repository } from "pinia-orm";
import DepartureAddress from "@/models/DepartureAddress";
import DepartureAddressService from "@/services/middler/DepartureAddressService";

export default class DepartureAddressRepository extends Repository {
  use = DepartureAddress;
  service = new DepartureAddressService();

  async list() {
    const results = await this.service.list();
    this.save(results);
  }

  async create(departureAddress) {
    const result = await this.service.create(departureAddress);
    this.save(result);
  }

  async update(departureAddress) {
    const result = await this.service.partialUpdate(
      departureAddress.id,
      departureAddress
    );
    this.save(result);
  }

  async remove(departureAddress) {
    await this.service.delete(departureAddress.id);
    this.destroy(departureAddress.id);
  }
}
