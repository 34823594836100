<template>
  <div>
    <v-btn
      v-for="mode in vehicleModes"
      :key="mode"
      active-class="primary--text"
      class="mb-2 mr-2"
      :input-value="value.includes(mode)"
      rounded
      @click="onBtnClick(mode)"
    >
      {{ $t(`transitModes.${mode}`) }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      vehicleModes: ["BUS", "RAIL", "SUBWAY", "TRAM"],
    };
  },
  methods: {
    onChange(value) {
      this.$emit("input", value);
    },
    onBtnClick(mode) {
      if (this.value.includes(mode)) {
        this.$emit(
          "input",
          this.value.filter((v) => v !== mode)
        );
      } else {
        this.$emit("input", [...this.value, mode]);
      }
    },
  },
};
</script>
