<template>
  <v-select
    :disabled="disabled"
    hide-details
    :items="items"
    :value="value"
    @change="onChange($event)"
  />
</template>

<script>
import {
  getRegionCodeForCountryCode,
  getSupportedCallingCodes,
} from "awesome-phonenumber";
import countryEmoji from "country-emoji";

export default {
  props: {
    value: {
      type: String,
      default: "33",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const callingCodes = getSupportedCallingCodes();
    const items = callingCodes.map((callingCode) => {
      const regionCode = getRegionCodeForCountryCode(callingCode);
      const flag = countryEmoji.flag(regionCode);
      if (flag) {
        return {
          text: `${flag}  +${callingCode}`,
          value: callingCode.toString(),
        };
      }
    });
    return {
      items,
    };
  },
  methods: {
    onChange(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-select__selection {
  white-space: pre;
}
</style>
