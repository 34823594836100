import ReconnectingWebSocket from "reconnecting-websocket";

const url = process.env.VUE_APP_MIDDLER_WS_API_URL;
const websocket = new ReconnectingWebSocket(url, null, {
  maxReconnectionDelay: 2500,
  minReconnectionDelay: 250 + Math.random() * 1000,
  startClosed: true,
});

export default websocket;
