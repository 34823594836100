<template>
  <v-icon v-if="type == 'restaurant'" v-bind="$attrs" v-on="$listeners">
    mdi-silverware-fork-knife
  </v-icon>
  <v-icon v-else-if="type == 'bar'" v-bind="$attrs" v-on="$listeners">
    mdi-glass-cocktail
  </v-icon>
  <v-icon v-else-if="type == 'cafe'" v-bind="$attrs" v-on="$listeners">
    mdi-coffee
  </v-icon>
  <v-icon v-else-if="type == 'stop'" v-bind="$attrs" v-on="$listeners">
    mdi-subway-variant
  </v-icon>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
</script>
