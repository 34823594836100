<template>
  <v-btn
    v-bind="$attrs"
    :to="{
      name: 'tripMapPlace',
      params: { tripId, placeId },
    }"
  >
    <span v-if="!$attrs.hasOwnProperty('fab')">
      {{ $t("seeOnMapBtn.label") }}
    </span>
    <v-icon>mdi-map</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    tripId: {
      type: String,
      required: true,
    },
    placeId: {
      type: String,
      required: true,
    },
  },
};
</script>
