<template>
  <v-switch :input-value="device.darkMode" @change="onChange" />
</template>

<script>
import { useDeviceStore } from "@/stores/device";
import _clone from "lodash/clone";

export default {
  data: () => {
    const deviceStore = useDeviceStore();
    const device = _clone(deviceStore.$state);
    return {
      deviceStore,
      device,
    };
  },
  methods: {
    async onChange(value) {
      this.$vuetify.theme.dark = value;
      this.device.darkMode = value;
      await this.deviceStore.update(this.device);
    },
  },
};
</script>
