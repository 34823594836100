<template>
  <v-switch
    :disabled="!notificationStore.isSupported"
    :input-value="notificationStore.isEnabled"
    :loading="notificationStore.loading"
    @change="toggle"
  />
</template>

<script>
import { mapStores } from "pinia";
import { useNotificationStore } from "@/stores/notification";
import NotificationHandler from "@/utils/NotificationHandler";

export default {
  data() {
    return {
      notificationHandler: new NotificationHandler(),
    };
  },
  computed: {
    ...mapStores(useNotificationStore),
  },
  methods: {
    async toggle() {
      await this.notificationHandler.toggle();
    },
  },
};
</script>
