import { Model } from "pinia-orm";

export default class Vote extends Model {
  static entity = "votes";
  static fields() {
    return {
      id: this.string(null),
      pollId: this.string(null),
      userId: this.string(null),
      value: this.boolean(null),
    };
  }
}
