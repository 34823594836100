<template>
  <div class="background">
    <v-app-bar app color="transparent" dark flat>
      <v-spacer />
      <language-select v-model="deviceStore.language" />
    </v-app-bar>
    <v-main class="fill-height">
      <v-container class="fill-height white--text" @click="hasStarted = false">
        <div class="logo-container">
          <img
            alt="Middler"
            src="@/assets/images/logo.svg"
            style="width: 70%"
          />
          <div class="text-h7 text-lg-h6">Paris - Île-de-France</div>
        </div>
        <div class="carousel-container">
          <v-fade-transition>
            <v-carousel
              v-if="!hasStarted"
              v-model="carousel"
              class="text-h7 text-lg-h6 text-center"
              :continuous="false"
              cycle
              height="150"
              hide-delimiter-background
              interval="2000"
              :show-arrows="false"
            >
              <v-carousel-item>
                <v-sheet class="d-flex flex-column" color="transparent" dark>
                  <v-icon class="mb-3" large>mdi-subway-variant</v-icon>
                  <div>{{ $t("userAuth.find") }}</div>
                </v-sheet>
              </v-carousel-item>
              <v-carousel-item>
                <v-sheet class="d-flex flex-column" color="transparent" dark>
                  <v-icon class="mb-3" large>mdi-silverware-fork-knife</v-icon>
                  <div>{{ $t("userAuth.discover") }}</div>
                </v-sheet>
              </v-carousel-item>
              <v-carousel-item>
                <v-sheet class="d-flex flex-column" color="transparent" dark>
                  <v-icon class="mb-3" large>mdi-thumb-up</v-icon>
                  <div>{{ $t("userAuth.simplify") }}</div>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-fade-transition>
        </div>
      </v-container>
    </v-main>
    <v-footer app>
      <v-container>
        <v-btn
          v-if="!hasStarted"
          block
          class="my-3"
          color="primary"
          large
          rounded
          @click="hasStarted = true"
        >
          {{ $t("userAuth.start") }}
        </v-btn>
        <div v-if="hasStarted" class="text-center">
          <div>{{ $t("userAuth.newToMiddler") }}</div>
          <v-btn
            block
            class="my-3"
            color="primary"
            large
            rounded
            :to="{
              name: 'userSignUpStep1',
              query: { redirect: $route.query.redirect },
            }"
          >
            {{ $t("userAuth.signUp") }}
          </v-btn>
          <v-divider class="my-5" />
          <div>{{ $t("userAuth.alreadyHaveAnAccount") }}</div>
          <v-btn
            block
            class="my-3"
            color="primary"
            large
            outlined
            rounded
            :to="{
              name: 'userLogin',
              query: { redirect: $route.query.redirect },
            }"
            >{{ $t("userAuth.login") }}</v-btn
          >
        </div>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useDeviceStore } from "@/stores/device";
import LanguageSelect from "@/components/LanguageSelect.vue";

export default {
  components: {
    LanguageSelect,
  },
  data() {
    return {
      carousel: 0,
      hasStarted: false,
    };
  },
  computed: {
    ...mapStores(useDeviceStore),
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: rgb(0, 176, 255);
  background: linear-gradient(
    135deg,
    rgba(0, 176, 255, 1) 0%,
    rgba(70, 198, 255, 1) 100%
  );
  height: 100%;
}
.carousel-container {
  height: 150px;
  width: 100%;
}
.logo-container {
  text-align: center;
  width: 100%;
}
</style>
