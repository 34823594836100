import { defineStore } from "pinia";

export const useNotificationStore = defineStore("notification", {
  state: () => {
    return {
      isEnabled: false,
      isSupported: null,
      loading: false,
    };
  },
});
