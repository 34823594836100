import { Capacitor } from "@capacitor/core";
import { Contacts } from "@capacitor-community/contacts";
import { Repository } from "pinia-orm";
import { getActivePinia } from "pinia";
import { useLoadingStore } from "@/stores/loading";
import User from "@/models/User";
import UserService from "@/services/middler/UserService";
import _isEmpty from "lodash/isEmpty";
import _pickBy from "lodash/pickBy";
import contactsToPhoneNumbers from "@/utils/contactsToPhoneNumbers";

function getChanges(instance, state) {
  return _pickBy(instance, (value, key) => {
    return state[key] !== value;
  });
}

export default class UserRepository extends Repository {
  use = User;
  service = new UserService();

  get authUser() {
    return this.where("isAuthenticated", true).first();
  }

  async listRelatedAndContactUsers() {
    const loadingStore = useLoadingStore();
    loadingStore["user.listRelatedAndContactUsers"] = true;
    const tripRelatedUsers = await this.service.list();
    let contactUsers = [];
    if (Capacitor.getPlatform() !== "web") {
      const projection = { phones: true };
      const { contacts } = await Contacts.getContacts({
        projection,
      });
      const phoneNumbers = contactsToPhoneNumbers(contacts);
      contactUsers = await this.service.filterByPhoneNumbers(phoneNumbers);
    }
    this.save([...tripRelatedUsers, ...contactUsers]);
    loadingStore["user.listRelatedAndContactUsers"] = false;
  }

  async listRelatedUsers() {
    const loadingStore = useLoadingStore();
    loadingStore["user.listRelatedUsers"] = true;
    const results = await this.service.list();
    this.save(results);
    loadingStore["user.listRelatedUsers"] = false;
  }

  async listByTrip(tripId, shareKey) {
    const params = shareKey ? { share_key: shareKey } : {};
    const results = await this.service.listByTrip(tripId, params);
    this.save(results);
  }

  async retrieveAuthUser() {
    const result = await this.service.retrieve("self");
    this.save(result);
  }

  async login(email, password) {
    const result = await this.service.login(email, password);
    this.save(result);
  }

  async logout() {
    await this.service.logout();
    getActivePinia()._s.forEach((store) => store.$reset());
  }

  async update(user, avatarFile) {
    const changes = getChanges(user, this.find(user.id));
    if (!!avatarFile || avatarFile === null) {
      changes["avatar"] = avatarFile;
    }
    const multipart = !!avatarFile;
    if (!_isEmpty(changes)) {
      const result = await this.service.partialUpdate(
        user.id,
        changes,
        multipart
      );
      this.save(result);
    }
  }

  async register(firstName, lastName, phoneNumber, verificationCode, password) {
    const result = await this.service.register(
      firstName,
      lastName,
      phoneNumber,
      verificationCode,
      password
    );
    this.save(result);
  }
}
