<template>
  <div>
    <v-img
      v-for="(mode, index) in modes"
      :key="index"
      :alt="transitModes[mode].name"
      class="mr-2"
      height="24"
      :src="transitModes[mode].svg"
      style="display: inline-block"
      width="24"
    />
  </div>
</template>

<script>
import { getTransitModes } from "@/constants/transitModes";

export default {
  props: {
    modes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    transitModes: getTransitModes,
  },
};
</script>
