import i18n from "@/plugins/i18n.js";

export const getTransitModes = () => ({
  BUS: {
    name: i18n.t("transitModes.BUS"),
    icon: "mdi-bus",
    svg: require("@/assets/images/ratp/bus.svg"),
  },
  RAIL: {
    name: i18n.t("transitModes.RAIL"),
    icon: "mdi-train",
    svg: require("@/assets/images/ratp/transilien.svg"),
  },
  SUBWAY: {
    name: i18n.t("transitModes.SUBWAY"),
    icon: "mdi-subway-variant",
    svg: require("@/assets/images/ratp/metro.svg"),
  },
  TRAM: {
    name: i18n.t("transitModes.TRAM"),
    icon: "mdi-tram",
    svg: require("@/assets/images/ratp/tram.svg"),
  },
});
