import { App as CapacitorApp } from "@capacitor/app";
import { NotFound } from "@/services/middler/errors.js";
import { useDeviceStore } from "@/stores/device";
import { useRepo } from "pinia-orm";
import MessageRepository from "@/repositories/MessageRepository";
import PlaceFilters from "@/views/PlaceFilters.vue";
import PlaceRepository from "@/repositories/PlaceRepository";
import PollRepository from "@/repositories/PollRepository";
import TripCreate from "@/views/TripCreate.vue";
import TripDetail from "@/views/TripDetail.vue";
import TripEdit from "@/views/TripEdit.vue";
import TripInfo from "@/views/TripInfo.vue";
import TripList from "@/views/TripList.vue";
import TripMap from "@/views/TripMap.vue";
import TripMapUserEdit from "@/views/TripMapUserEdit.vue";
import TripMessages from "@/views/TripMessages.vue";
import TripPlace from "@/views/TripPlace.vue";
import TripPlaces from "@/views/TripPlaces.vue";
import TripRepository from "@/repositories/TripRepository";
import TripUserAdd from "@/views/TripUserAdd.vue";
import TripUserEdit from "@/views/TripUserEdit.vue";
import TripUsers from "@/views/TripUsers.vue";
import UserAuth from "@/views/UserAuth.vue";
import UserEnableNotifications from "@/views/UserEnableNotifications.vue";
import UserLogin from "@/views/UserLogin.vue";
import UserRepository from "@/repositories/UserRepository";
import UserResetPasswordStep1 from "@/views/UserResetPasswordStep1.vue";
import UserResetPasswordStep2 from "@/views/UserResetPasswordStep2.vue";
import UserResetPasswordStep3 from "@/views/UserResetPasswordStep3.vue";
import UserSettings from "@/views/UserSettings.vue";
import UserSettingsAvatar from "@/views/UserSettingsAvatar.vue";
import UserSettingsDefaultTravelMode from "@/views/UserSettingsDefaultTravelMode.vue";
import UserSettingsHelp from "@/views/UserSettingsHelp.vue";
import UserSettingsLanguage from "@/views/UserSettingsLanguage.vue";
import UserSettingsName from "@/views/UserSettingsName.vue";
import UserSettingsPassword from "@/views/UserSettingsPassword.vue";
import UserSignUpStep1 from "@/views/UserSignUpStep1.vue";
import UserSignUpStep2 from "@/views/UserSignUpStep2.vue";
import UserSignUpStep3 from "@/views/UserSignUpStep3.vue";
import VueRouter from "vue-router";
import websocket from "@/websocket";

const routes = [
  {
    path: "*",
    redirect: { name: "tripList" },
  },
  {
    path: "/",
    name: "tripList",
    component: TripList,
    meta: { authRequired: true },
  },
  {
    path: "/create",
    name: "tripCreate",
    component: TripCreate,
    meta: { authRequired: true, backRouteName: "tripList" },
  },
  {
    path: "/auth",
    name: "userAuth",
    component: UserAuth,
  },
  {
    path: "/login",
    name: "userLogin",
    component: UserLogin,
    meta: { backRouteName: "userAuth" },
  },
  {
    path: "/signup/1",
    name: "userSignUpStep1",
    component: UserSignUpStep1,
    meta: { backRouteName: "userAuth" },
  },
  {
    path: "/signup/2",
    name: "userSignUpStep2",
    component: UserSignUpStep2,
    meta: { backRouteName: "userSignUpStep1" },
  },
  {
    path: "/signup/3",
    name: "userSignUpStep3",
    component: UserSignUpStep3,
    meta: { backRouteName: "userSignUpStep1" },
  },
  {
    path: "/user/enable-notifications",
    name: "enableNotifications",
    component: UserEnableNotifications,
    meta: { authRequired: true },
  },
  {
    path: "/user/reset-password/step1",
    name: "userResetPasswordStep1",
    component: UserResetPasswordStep1,
    meta: { backRouteName: "userAuth" },
  },
  {
    path: "/user/reset-password/step2",
    name: "userResetPasswordStep2",
    component: UserResetPasswordStep2,
    meta: { backRouteName: "userResetPasswordStep1" },
  },
  {
    path: "/user/reset-password/step3",
    name: "userResetPasswordStep3",
    component: UserResetPasswordStep3,
    meta: { backRouteName: "userResetPasswordStep1" },
  },
  {
    path: "/user/settings",
    name: "userSettings",
    component: UserSettings,
    meta: { authRequired: true, backRouteName: "tripList" },
  },
  {
    path: "/user/settings/avatar",
    name: "userSettingsAvatar",
    component: UserSettingsAvatar,
    meta: { authRequired: true, backRouteName: "userSettings" },
  },
  {
    path: "/user/settings/default-travel-mode",
    name: "userSettingsDefaultTravelMode",
    component: UserSettingsDefaultTravelMode,
    meta: { authRequired: true, backRouteName: "userSettings" },
  },
  {
    path: "/user/settings/help",
    name: "userSettingsHelp",
    component: UserSettingsHelp,
    meta: { authRequired: true, backRouteName: "userSettings" },
  },
  {
    path: "/user/settings/language",
    name: "userSettingsLanguage",
    component: UserSettingsLanguage,
    meta: { authRequired: true, backRouteName: "userSettings" },
  },
  {
    path: "/user/settings/name",
    name: "userSettingsName",
    component: UserSettingsName,
    meta: { authRequired: true, backRouteName: "userSettings" },
  },
  {
    path: "/user/settings/password",
    name: "userSettingsPassword",
    component: UserSettingsPassword,
    meta: { authRequired: true, backRouteName: "userSettings" },
  },
  {
    path: "/:tripId",
    component: TripDetail,
    meta: { authRequired: true, backRouteName: "tripList" },
    children: [
      {
        path: "",
        name: "tripInfo",
        component: TripInfo,
        meta: { backRouteName: "tripList" },
      },
      {
        path: "edit",
        name: "tripEdit",
        component: TripEdit,
        meta: { backRouteName: "tripList" },
      },
      {
        path: "messages/:messageId?",
        name: "tripMessages",
        component: TripMessages,
        meta: { backRouteName: "tripList" },
      },
      {
        path: "map",
        name: "tripMap",
        component: TripMap,
        meta: { backRouteName: "tripList" },
        children: [
          {
            path: "place/:placeId",
            name: "tripMapPlace",
            component: TripMap,
            meta: { backRouteName: "tripMap" },
          },
          {
            path: "user/:userId",
            name: "tripMapUser",
            component: TripMap,
            meta: { backRouteName: "tripMap" },
          },
          {
            path: "user/:userId/edit",
            name: "tripMapUserEdit",
            component: TripMapUserEdit,
            meta: { backRouteName: "tripMap" },
          },
        ],
      },
      {
        path: "users",
        name: "tripUsers",
        component: TripUsers,
        meta: { backRouteName: "tripList" },
      },
      {
        path: "users/add",
        name: "tripUserAdd",
        component: TripUserAdd,
        meta: { backRouteName: "tripList" },
      },
      {
        path: "users/:userId/edit",
        name: "tripUserEdit",
        component: TripUserEdit,
        meta: { backRouteName: "tripList" },
      },
      {
        path: "places",
        name: "tripPlaces",
        component: TripPlaces,
        meta: { backRouteName: "tripList" },
      },
      {
        path: "places/:placeId",
        name: "tripPlace",
        component: TripPlace,
        meta: { backRouteName: "tripList" },
      },
      {
        path: "filters",
        name: "placeFilters",
        component: PlaceFilters,
        meta: { backRouteName: "tripList" },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

CapacitorApp.addListener("appUrlOpen", (event) => {
  const url = new URL(event.url);
  if (url.host === process.env.VUE_APP_URL_HOST) {
    router.push({
      path: url.pathname,
      query: Object.fromEntries(url.searchParams),
    });
  }
});

router.beforeEach(async (to, from, next) => {
  const authRequired = to.matched[0].meta.authRequired;
  const userRepo = useRepo(UserRepository);

  if (authRequired && !userRepo.authUser) {
    // Redirecting to the login page in case of a 403 error is handled by the client interceptor.
    // (main.js). However, since the browser's back button stops working, we also need to catch the
    // error here.
    try {
      await userRepo.retrieveAuthUser();
    } catch (error) {
      return next();
    }
    const deviceStore = useDeviceStore();
    await deviceStore.fetchOrCreate();
    websocket.reconnect();
  }

  // when entering trip detail route
  if (
    to.matched[0].path.startsWith("/:tripId") &&
    (!from.matched[0] ||
      (from.matched[0] && !from.matched[0].path.startsWith("/:tripId")))
  ) {
    const shareKey = to.query.shareKey;
    const tripId = to.params.tripId;
    const messageRepo = useRepo(MessageRepository);
    const placeRepo = useRepo(PlaceRepository);
    const pollRepo = useRepo(PollRepository);
    const tripRepo = useRepo(TripRepository);

    try {
      await tripRepo.retrieve(tripId, shareKey);
      userRepo.listByTrip(tripId, shareKey);
      if (!shareKey) {
        messageRepo.list(tripId).then(() => {
          messageRepo.markAsReceived(tripId);
        });
        placeRepo.list(tripId);
        placeRepo.search(tripId);
        pollRepo.list(tripId);
      }
    } catch (error) {
      if (error instanceof NotFound) {
        return next({ name: "tripList" });
      }
    }
  }

  next();
});

export default router;
