<template>
  <v-input :error-messages="errorMessages" :label="label">
    <v-file-input
      v-if="!image"
      accept="image/*"
      :clearable="false"
      hide-details
      :placeholder="$t('imageInput.placeholder')"
      prepend-icon="mdi-camera"
      show-size
      @change="load"
    />
    <div v-if="image" class="cropper-container mt-6">
      <cropper
        image-restriction="stencil"
        :resize-image="{
          adjustStencil: false,
        }"
        :src="image"
        stencil-component="circle-stencil"
        :stencil-props="{
          handlers: {},
          movable: false,
          resizable: false,
          aspectRatio: 1,
        }"
        :stencil-size="{
          width: 300,
          height: 300,
        }"
        @change="change"
      />
      <v-btn v-if="image" class="close-btn" fab x-small @click="remove">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-input>
</template>

<script>
import "vue-advanced-cropper/dist/style.css";
import { Cropper } from "vue-advanced-cropper";
import { VInput } from "vuetify/lib";

export default {
  components: {
    Cropper,
  },
  extends: VInput,
  props: {
    url: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
  },
  data: () => {
    return {
      image: null,
      hasChanged: false,
    };
  },
  mounted() {
    this.image = this.url;
  },
  methods: {
    change({ canvas }) {
      if (!this.hasChanged) {
        return;
      }
      canvas.toBlob((blob) => {
        const file = new File([blob], "avatar.jpg", {
          type: "image/jpeg",
          lastModified: Date.now(),
        });
        this.$emit("change", file);
      });
    },
    load(value) {
      this.hasChanged = true;
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        this.image = reader.result;
      };
    },
    remove() {
      this.image = null;
      this.$emit("change", null);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-label {
  font-size: 0.8rem;
  position: absolute !important;
  top: 0px;
}
.cropper-container {
  height: 300px;
  width: 300px;
  position: relative;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
</style>
