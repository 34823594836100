<template>
  <v-btn color="primary" text @click="addToCalendar">
    <v-icon left>mdi-calendar-plus</v-icon>
    {{ $t("addToCalendarBtn.label") }}
  </v-btn>
</template>

<script>
import * as ics from "ics";
import { Capacitor } from "@capacitor/core";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";

export default {
  props: {
    tripId: {
      type: String,
      required: true,
    },
  },
  computed: {
    trip() {
      return this.$tripRepo.with("chosenPlace").find(this.tripId);
    },
  },
  methods: {
    async addToCalendar() {
      const event = await this.createEvent({
        productId: "Middler",
        uid: `middler-${this.trip.id}`,
        start: Date.parse(this.trip.arrivalDatetime),
        title: this.trip.name,
        description: this.trip.description,
        location: `${this.trip.chosenPlace.name}, ${this.trip.chosenPlace.address}`,
        geo: { lat: this.trip.chosenPlace.lat, lon: this.trip.chosenPlace.lng },
      });
      const filename = `middler-${this.trip.id}.ics`;

      if (Capacitor.isNativePlatform()) {
        const file = await Filesystem.writeFile({
          path: filename,
          directory: Directory.Cache,
          data: event,
          encoding: Encoding.UTF8,
        });
        await FileOpener.open({
          filePath: file.uri,
        });
      } else {
        const file = new File([event], filename, {
          type: "text/calendar",
        });
        const url = URL.createObjectURL(file);

        // trying to assign the file URL to a window could cause cross-site issues so this is a
        // workaround using HTML5
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = filename;

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        URL.revokeObjectURL(url);
      }
    },
    async createEvent(event) {
      return new Promise((resolve, reject) => {
        ics.createEvent(event, (error, value) => {
          if (error) {
            reject(error);
          }
          resolve(value);
        });
      });
    },
  },
};
</script>
