<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";

export default {
  inject: ["map"],
  props: {
    anchor: {
      type: String,
      default: "center",
    },
    color: {
      type: String,
      default: "#3fb1ce",
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    lngLat: {
      type: Array,
      required: true,
    },
    offset: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      marker: null,
    };
  },
  mounted() {
    [this.el] = this.$el.children;
    this.create();
    this.$watch("color", () => {
      this.marker.remove();
      this.create();
    });
    this.$watch("lngLat", (value) => {
      this.marker.setLngLat(value);
    });
  },
  beforeDestroy() {
    if (this.marker) {
      this.marker.remove();
    }
  },
  methods: {
    create() {
      this.marker = new mapboxgl.Marker({
        anchor: this.anchor,
        color: this.color,
        draggable: this.draggable,
        element: this.el,
        offset: this.offset,
      });
      const canvas = this.map.getCanvasContainer();
      this.marker.setLngLat(this.lngLat).addTo(this.map);

      if (this.draggable) {
        this.marker.on("dragstart", () => {
          canvas.style.cursor = "grabbing";
          this.$emit("dragstart");
        });
        this.marker.on("dragend", () => {
          canvas.style.cursor = "grab";
          this.$emit("dragend", this.marker.getLngLat());
        });
      }

      this.el.addEventListener("click", (event) => {
        event.stopPropagation();
        this.$emit("click");
      });
    },
  },
};
</script>
