import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { useDeviceStore } from "@/stores/device";
import { useNotificationStore } from "@/stores/notification";

export default class NotificationHandler {
  constructor() {
    this.deviceStore = useDeviceStore();
    this.notificationStore = useNotificationStore();
    this.init();
  }

  async init() {
    const { isSupported } = await FirebaseMessaging.isSupported();
    this.notificationStore.isSupported = isSupported;

    if (isSupported) {
      this.notificationStore.loading = true;
      const permissionStatus = await FirebaseMessaging.checkPermissions();
      if (permissionStatus.receive === "granted" && this.deviceStore.fcmToken) {
        this.notificationStore.isEnabled = true;
      }
      this.notificationStore.loading = false;
    }

    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then((permission) => {
          permission.onchange = () => {
            if (permission.state === "granted") {
              this.notificationStore.isEnabled = true;
            } else {
              if (this.deviceStore.fcmToken) {
                this.deleteToken();
              }
            }
          };
        });
    }
  }

  async toggle() {
    const permissionStatus = await FirebaseMessaging.checkPermissions();
    if (permissionStatus.receive !== "granted") {
      const permissionStatus = await FirebaseMessaging.requestPermissions();
      if (permissionStatus.receive === "granted") {
        await this.getAndSaveToken();
      }
    } else if (permissionStatus.receive === "granted") {
      if (!this.deviceStore.fcmToken) {
        await this.getAndSaveToken();
      } else {
        await this.deleteToken();
      }
    }
  }

  async getAndSaveToken() {
    this.notificationStore.loading = true;
    const options = {
      vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
    };
    const result = await FirebaseMessaging.getToken(options);
    if (result.token) {
      this.deviceStore.fcmToken = result.token;
      await this.deviceStore.save();
      this.notificationStore.isEnabled = true;
    } else {
      this.notificationStore.isEnabled = false;
    }
    this.notificationStore.loading = false;
  }

  async deleteToken() {
    this.notificationStore.loading = true;
    await FirebaseMessaging.deleteToken();
    this.deviceStore.fcmToken = null;
    await this.deviceStore.save();
    this.notificationStore.loading = false;
    this.notificationStore.isEnabled = false;
  }
}
