import { defineStore } from "pinia";

export const useNetworkStore = defineStore("network", {
  state: () => {
    return {
      connected: false,
      connectionType: "none",
    };
  },
});
