import { Model } from "pinia-orm";

export default class TripPlace extends Model {
  static entity = "tripPlaces";
  static primaryKey = ["tripId", "placeId"];
  static fields() {
    return {
      tripId: this.string(null),
      placeId: this.string(null),
    };
  }
}
