import BaseService from "@/services/middler/BaseService";

export default class DepartureAddressService extends BaseService {
  basename = "departure-addresses";

  async list(params) {
    const config = { params };
    const response = await this.client.get(this.basename, config);
    return response.data;
  }

  async create(data) {
    const response = await this.client.post(this.basename, data);
    return response.data;
  }

  async delete(id) {
    await this.client.delete(`${this.basename}/${id}`);
  }
}
