import BaseService from "@/services/middler/BaseService";

export default class PollService extends BaseService {
  async list(tripId) {
    const path = `trips/${tripId}/polls`;
    const response = await this.client.get(path);
    return response.data;
  }

  async create(tripId, data) {
    const path = `trips/${tripId}/polls`;
    const response = await this.client.post(path, data);
    return response.data;
  }

  async retrieve(tripId, id) {
    const path = `trips/${tripId}/polls/${id}`;
    const response = await this.client.get(path);
    return response.data;
  }

  async vote(tripId, id, data) {
    const path = `trips/${tripId}/polls/${id}/vote`;
    const response = await this.client.post(path, data);
    return response.data;
  }
}
