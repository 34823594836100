<template>
  <v-btn :href="googleMapsDirectionsUrl" rel="noreferrer" target="_blank">
    <span>{{ $t("directionsBtn.label") }}</span>
    <v-icon>mdi-directions</v-icon>
  </v-btn>
</template>

<script>
import { getTravelModes } from "@/constants/travelModes";

export default {
  props: {
    origin: {
      type: Object,
      required: true,
    },
    destination: {
      type: Object,
      required: true,
    },
    travelMode: {
      type: String,
      required: true,
    },
    arrivalDatetime: {
      type: String,
      required: true,
    },
  },
  computed: {
    googleMapsDirectionsUrl() {
      const url = new URL("https://www.google.com/maps/dir/");
      const params = new URLSearchParams({
        api: 1,
        origin: `${this.origin.lat},${this.origin.lng}`,
        destination: `${this.destination.lat},${this.destination.lng}`,
        travelmode: this.travelModes[this.travelMode].google,
      });
      url.search = params.toString();
      return url.toString();
    },
    travelModes: getTravelModes,
  },
};
</script>
