<template>
  <v-main class="fill-height">
    <v-container v-if="loading" class="d-flex justify-center" fill-height>
      <v-progress-circular
        color="primary"
        indeterminate
        :size="70"
      ></v-progress-circular>
    </v-container>

    <v-container v-if="!loading">
      <div class="mb-3">
        <v-btn
          class="map-btn mr-2"
          color="primary"
          rounded
          small
          @click="onMapBtnClick"
        >
          <v-icon left>mdi-map</v-icon>
          {{ $t("tripPlaces.map") }}
        </v-btn>
        <v-btn
          color="primary"
          rounded
          small
          :to="{ name: 'placeFilters', params: { tripId: tripId } }"
        >
          <v-icon left>mdi-filter</v-icon>
          {{ $t("tripMap.filters") }}
        </v-btn>
      </div>
      <place-card
        v-for="(place, index) in places"
        :key="place.id"
        class="mb-4"
        :index="index"
        :place-id="place.id"
        :trip-id="tripId"
      />
    </v-container>
  </v-main>
</template>

<script>
import { mapStores } from "pinia";
import { useConfigStore } from "@/stores/config";
import { useLoadingStore } from "@/stores/loading";
import PlaceCard from "@/components/PlaceCard";

export default {
  components: {
    PlaceCard,
  },
  data() {
    return {
      tripId: this.$route.params.tripId,
    };
  },
  computed: {
    ...mapStores(useConfigStore, useLoadingStore),
    loading() {
      return (
        this.loadingStore["place.list"] || this.loadingStore["place.search"]
      );
    },
    places() {
      return this.trip.places.filter((place) => place.isSearchResult);
    },
    trip() {
      return this.$tripRepo.with("places").find(this.tripId);
    },
  },
  methods: {
    onMapBtnClick() {
      this.configStore.placeViewName = "tripMap";
      this.$router.push({ name: "tripMap", params: { tripId: this.tripId } });
    },
  },
};
</script>
