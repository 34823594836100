<template>
  <div>
    <v-app-bar app color="transparent" flat>
      <go-back-btn exact />
      <v-spacer />
      <v-toolbar-title class="mr-8">
        {{ $t("userSettingsDefaultTravelMode.title") }}
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <v-main>
      <v-container>
        <v-list flat>
          <v-list-item-group @change="onChange">
            <div v-for="(item, key, index) in travelModes" :key="key">
              <v-list-item :value="key">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-icon v-if="key === user.defaultTravelMode">
                  <v-icon>mdi-check</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider v-if="index < Object.keys(travelModes).length - 1" />
            </div>
          </v-list-item-group>
        </v-list>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { getTravelModes } from "@/constants/travelModes";
import { useRepo } from "pinia-orm";
import GoBackBtn from "@/components/buttons/GoBackBtn.vue";
import UserRepository from "@/repositories/UserRepository";
import _clone from "lodash/clone";

export default {
  components: {
    GoBackBtn,
  },
  data: () => {
    const userRepo = useRepo(UserRepository);
    const user = _clone(userRepo.authUser);
    return {
      user,
    };
  },
  computed: {
    travelModes: getTravelModes,
  },
  methods: {
    async onChange(travelMode) {
      this.user.defaultTravelMode = travelMode;
      await this.$userRepo.update(this.user);
      this.$router.push({ name: "userSettings" });
    },
  },
};
</script>
