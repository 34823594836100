import { Repository } from "pinia-orm";
import Poll from "@/models/Poll";
import PollService from "@/services/middler/PollService";

export default class PollRepository extends Repository {
  use = Poll;
  service = new PollService();

  async list(tripId) {
    const results = await this.service.list(tripId);
    results.forEach((poll) => {
      poll.trip = {
        id: tripId,
      };
    });
    this.save(results);
  }

  async create(tripId, placeId) {
    const result = await this.service.create(tripId, {
      placeId,
    });
    return this.save(result);
  }

  async vote(tripId, id, value) {
    const result = await this.service.vote(tripId, id, { value });
    this.save(result);
  }
}
