<template>
  <div>
    <div
      v-for="tripUserPlace in tripUserPlaces"
      :key="tripUserPlace.id"
      class="mb-1"
    >
      <div class="d-flex justify-space-between">
        {{ tripUserPlace.tripUser.user.fullName }}
        <span>
          <v-icon>
            {{ travelModes[tripUserPlace.tripUser.travelMode].icon }}
          </v-icon>
          {{ strfSeconds(tripUserPlace.duration) }}
        </span>
      </div>
      <v-progress-linear
        color="secondary"
        height="5"
        rounded
        :value="(tripUserPlace.duration / maxDuration) * 100"
      />
    </div>
  </div>
</template>

<script>
import { getTravelModes } from "@/constants/travelModes";
import strfSeconds from "@/utils/strfSeconds";

export default {
  props: {
    tripId: {
      type: String,
      required: true,
    },
    placeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      strfSeconds,
    };
  },
  computed: {
    maxDuration() {
      return Math.max(
        ...this.tripUserPlaces.map((tripUserPlace) => tripUserPlace.duration)
      );
    },
    travelModes: getTravelModes,
    tripUserPlaces() {
      return this.$tripUserPlaceRepo
        .where("tripId", this.tripId)
        .where("placeId", this.placeId)
        .with("tripUser", (query) => {
          query.with("user");
        })
        .get();
    },
  },
};
</script>
