<template>
  <div class="fill-height">
    <v-app-bar app color="primary" dark flat>
      <img alt="Middler" class="logo" src="@/assets/images/logo.svg" />
      <v-spacer />
      <v-btn dark icon :to="{ name: 'userSettings' }">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main class="fill-height">
      <v-container v-if="loading" class="d-flex justify-center" fill-height>
        <v-progress-circular
          color="primary"
          indeterminate
          :size="70"
        ></v-progress-circular>
      </v-container>

      <v-container
        v-if="!loading && !trips.length"
        class="d-flex flex-column justify-center text-center"
        fill-height
      >
        <div class="text-h4 mb-7">{{ $t("tripList.emptyTitle") }}</div>
        <div>{{ $t("tripList.emptyDescription") }}</div>
      </v-container>

      <v-container v-if="!loading && trips.length">
        <v-expansion-panels v-model="panel" accordion flat multiple>
          <v-expansion-panel v-show="upcomingTrips.length">
            <v-expansion-panel-header class="title">
              {{ $t("tripList.upcomingTrips") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <trip-card
                v-for="trip in upcomingTrips"
                :key="`upcoming-trip-${trip.id}`"
                class="mb-4"
                :trip="trip"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-show="pastTrips.length">
            <v-expansion-panel-header class="title">
              {{ $t("tripList.pastTrips") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <trip-card
                v-for="trip in pastTrips"
                :key="`past-trip-${trip.id}`"
                class="mb-4"
                :trip="trip"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-show="invitedTrips.length">
            <v-expansion-panel-header class="title">
              {{ $t("tripList.invitations") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <trip-card
                v-for="trip in invitedTrips"
                :key="`past-trip-${trip.id}`"
                class="mb-4"
                :trip="trip"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-main>
    <v-footer app>
      <v-container>
        <v-btn
          block
          class="my-3"
          color="primary"
          large
          rounded
          :to="{ name: 'tripCreate' }"
        >
          {{ $t("tripList.newTrip") }}
        </v-btn>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useLoadingStore } from "@/stores/loading";
import TripCard from "@/components/TripCard.vue";
import _sortBy from "lodash/sortBy";

export default {
  components: {
    TripCard,
  },
  data() {
    return {
      panel: [0, 2],
    };
  },
  computed: {
    ...mapStores(useLoadingStore),
    loading() {
      return (
        this.loadingStore["trip.list"] ||
        this.loadingStore["user.listRelatedUsers"]
      );
    },
    trips() {
      return this.$tripRepo.with("users").get();
    },
    joinedTrips() {
      return this.trips.filter((trip) => {
        return trip.users.some(
          (user) => user.id === this.$userRepo.authUser.id && user.pivot.joined
        );
      });
    },
    invitedTrips() {
      return _sortBy(
        this.trips.filter((trip) => {
          return trip.users.some(
            (user) =>
              user.id === this.$userRepo.authUser.id &&
              !user.pivot.joined &&
              new Date(trip.arrivalDatetime) >= new Date()
          );
        }),
        "arrivalDatetime"
      );
    },
    pastTrips() {
      return _sortBy(
        this.joinedTrips.filter((trip) => {
          return new Date(trip.arrivalDatetime) < new Date();
        }),
        "arrivalDatetime"
      ).reverse();
    },
    upcomingTrips() {
      return _sortBy(
        this.joinedTrips.filter((trip) => {
          return new Date(trip.arrivalDatetime) >= new Date();
        }),
        "arrivalDatetime"
      );
    },
  },
  created() {
    this.$tripRepo.list();
    this.$userRepo.listRelatedUsers();
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 20px;
  left: 50%;
  position: fixed;
  transform: translateX(-50%);
}
</style>
