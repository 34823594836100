<template>
  <div>
    <v-dialog v-if="isWebPlatform" v-model="modal" width="290px">
      <template #activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          :error-messages="errors"
          :label="label"
          prepend-icon="mdi-calendar"
          readonly
          :value="value"
          v-on="on"
        />
      </template>
      <v-date-picker
        :locale="language"
        :min="min"
        scrollable
        :value="value"
        @input="
          $emit('input', $event);
          modal = false;
        "
      />
    </v-dialog>
    <v-text-field
      v-else
      :error-messages="errors"
      :label="label"
      type="date"
      :value="value"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import { VInput } from "vuetify/lib";

export default {
  extends: VInput,
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    language: {
      type: String,
      required: true,
    },
    min: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      modal: false,
    };
  },
  computed: {
    isWebPlatform() {
      return Capacitor.getPlatform() === "web";
    },
  },
};
</script>
