<template>
  <svg
    id="svg12"
    class=""
    :height="height"
    style="enable-background: new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    :width="width"
    x="0"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    y="0"
  >
    <metadata id="metadata18">
      <rdf:RDF
        ><cc:Work rdf:about=""
          ><dc:format>image/svg+xml</dc:format
          ><dc:type
            rdf:resource="http://purl.org/dc/dcmitype/StillImage" /><dc:title></dc:title></cc:Work
      ></rdf:RDF>
    </metadata>
    <defs id="defs16" />
    <g id="g10">
      <path
        id="path2"
        class=""
        d="M256 509c-5.2 0-10.1-2.5-13.1-6.7-1.8-2.5-4.4-5.9-7.6-10.3-38.4-51.4-155-208-154.6-313.8C80.8 81.5 159.2 3.1 256 3c47.8 0 91.7 17 123.7 47.8 33.1 31.9 51.5 77.2 51.7 127.5.2 48.2-19.9 107.4-59.6 176.1-32.1 55.5-69.8 104.8-90.1 131.2-5.5 7.1-9.8 12.8-12.5 16.5-3.1 4.4-8 6.9-13.2 6.9z"
        :fill="color"
        opacity="1"
      />
      <path
        id="path4"
        class=""
        d="M419.4 178.4c.4 121.9-136.5 284.1-160 316.9-1.4 1.9-4 2.3-5.9 1-.4-.3-.7-.6-1-1C229 462.8 92.2 289 92.6 178.4 93 88.1 165.8 15 256 15s163 64 163.4 163.4z"
        fill="#ffffff"
        opacity="1"
      />
      <circle
        id="circle6"
        class=""
        cx="256"
        cy="177.9"
        :fill="color"
        opacity="1"
        r="139.3"
      />
    </g>
    <text
      id="text830"
      style="
        font-weight: bold;
        font-size: 192px;
        line-height: 1.25;
        font-family: sans-serif;
        fill: #ffffff;
        fill-opacity: 1;
      "
      x="347.875"
      xml:space="preserve"
      y="244.57812"
    >
      <tspan
        id="tspan828"
        style="
          font-style: normal;
          font-variant: normal;
          font-weight: bold;
          font-stretch: normal;
          font-family: sans-serif;
          text-align: center;
          text-anchor: middle;
        "
        x="250.1875"
        y="244.57812"
      >
        {{ number }}
      </tspan>
    </text>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      default: 48,
    },
    width: {
      type: Number,
      default: 48,
    },
  },
};
</script>
