import { defineStore } from "pinia";

export const useLoadingStore = defineStore("loading", {
  state: () => ({
    "message.list": false,
    "place.list": false,
    "place.search": false,
    "trip.list": false,
    "user.listRelatedUsers": false,
    "user.listRelatedAndContactUsers": false,
  }),
});
