import { Model } from "pinia-orm";
import TripUser from "@/models/TripUser";

export default class tripUserPlace extends Model {
  static entity = "tripUserPlaces";
  static primaryKey = ["tripUserId", "placeId"];
  static fields() {
    return {
      tripUserId: this.string(null),
      tripUser: this.belongsTo(TripUser, "tripUserId"),
      tripId: this.string(null),
      placeId: this.string(null),
      distance: this.number(null),
      duration: this.number(null),
    };
  }
}
