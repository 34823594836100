class BusinessException extends Error {
  constructor(message, code) {
    super(message);
    this.name = "BusinessException";
    this.code = code;
  }
}

class NetworkError extends Error {
  constructor() {
    super();
    this.message = "Trouble connecting to the Internet.";
  }
}

class MiddlerAPIError extends Error {
  constructor(message, data) {
    super(message);
    this.name = "MiddlerAPIError";
    this.data = data;
  }
}

class NotFound extends Error {}

class TooManyRequests extends Error {
  constructor(message, timeout) {
    super(message);
    this.timeout = timeout;
  }
}

export {
  BusinessException,
  NetworkError,
  MiddlerAPIError,
  NotFound,
  TooManyRequests,
};
