<template>
  <v-card v-if="poll" flat :width="'fit-content'">
    <v-card-text>
      <router-link
        :to="{
          name: 'tripPlace',
          params: { tripId: poll.tripId, placeId: poll.placeId },
        }"
      >
        {{ poll.place.name }}
      </router-link>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="hasVotedUp" @click="vote(true)">
        <v-badge bottom :content="upvoteCount">
          <v-icon>mdi-thumb-up</v-icon>
        </v-badge>
      </v-btn>
      <v-btn :disabled="hasVotedDown" @click="vote(false)">
        <v-badge bottom :content="downvoteCount">
          <v-icon>mdi-thumb-down</v-icon>
        </v-badge>
      </v-btn>
      <choose-place-btn
        v-if="authTripUser.isAdmin"
        :place="poll.place"
        :trip="trip"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import ChoosePlaceBtn from "@/components/buttons/ChoosePlaceBtn.vue";

export default {
  components: {
    ChoosePlaceBtn,
  },
  props: {
    pollId: {
      type: String,
      required: true,
    },
  },
  computed: {
    authTripUser() {
      return this.$tripUserRepo
        .where("tripId", this.poll.tripId)
        .where("userId", this.$userRepo.authUser.id)
        .first();
    },
    poll() {
      return this.$pollRepo
        .with("user")
        .with("place")
        .with("votes")
        .find(this.pollId);
    },
    trip() {
      return this.$tripRepo.find(this.poll.tripId);
    },
    tripUserCount() {
      return this.$tripUserRepo.where("tripId", this.poll.tripId).get().length;
    },
    upvoteCount() {
      return this.poll.votes
        .filter((vote) => vote.value === true)
        .length.toString();
    },
    downvoteCount() {
      return this.poll.votes
        .filter((vote) => vote.value === false)
        .length.toString();
    },
    hasVotedUp() {
      return this.poll.votes.some(
        (vote) =>
          vote.value === true && vote.userId === this.$userRepo.authUser.id
      );
    },
    hasVotedDown() {
      return this.poll.votes.some(
        (vote) =>
          vote.value === false && vote.userId === this.$userRepo.authUser.id
      );
    },
  },
  methods: {
    vote(value) {
      this.$pollRepo.vote(this.poll.tripId, this.poll.id, value);
    },
  },
};
</script>
