<template>
  <v-autocomplete
    chips
    deletable-chips
    :items="items"
    multiple
    :value="value"
    @change="onChange($event)"
  ></v-autocomplete>
</template>

<script>
import cuisineKeys from "@/constants/cuisineKeys";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      items: cuisineKeys.map((key) => ({
        text: this.$t(`cuisines.${key}`),
        value: key,
      })),
    };
  },
  methods: {
    onChange(value) {
      this.$emit("input", value);
    },
  },
};
</script>
