<template>
  <div>
    <v-label>{{ label }}</v-label>
    <v-item-group class="mt-1" :value="value" @change="onChange($event)">
      <v-item
        v-for="(mode, key) in travelModes"
        :key="key"
        v-slot="{ active, toggle }"
        :value="key"
      >
        <v-btn
          active-class="primary--text"
          class="mb-2 mr-2"
          :input-value="active"
          rounded
          @click="toggle"
        >
          <v-icon left>{{ mode.icon }}</v-icon>
          {{ mode.name }}
        </v-btn>
      </v-item>
    </v-item-group>
  </div>
</template>

<script>
import { VInput } from "vuetify/lib";
import { getTravelModes } from "@/constants/travelModes";

export default {
  extends: VInput,
  computed: {
    travelModes: getTravelModes,
  },
  methods: {
    onChange(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-label {
  font-size: 0.8rem;
}
</style>
