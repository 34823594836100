<template>
  <div>
    <v-app-bar app color="transparent" flat>
      <go-back-btn :query="{ redirect: $route.query.redirect }" />
    </v-app-bar>
    <validation-observer
      ref="form"
      v-slot="{ invalid }"
      tag="form"
      @submit.prevent="onSubmit"
    >
      <v-main>
        <v-container>
          <div class="mb-5 text-h5">{{ $t("userSignUpStep3.title") }}</div>
          <p>{{ $t("userSignUpStep3.description") }}</p>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('userSignUpStep3.firstName')"
            rules="required"
            vid="firstName"
          >
            <v-text-field
              v-model="firstName"
              autocomplete="given-name"
              :error-messages="showFieldErrors ? errors : []"
              :label="$t('userSignUpStep3.firstName')"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('userSignUpStep3.lastName')"
            rules="required"
            vid="lastName"
          >
            <v-text-field
              v-model="lastName"
              autocomplete="family-name"
              :error-messages="showFieldErrors ? errors : []"
              :label="$t('userSignUpStep3.lastName')"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('userSignUpStep3.password')"
            rules="required|min:10"
            vid="password"
          >
            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              autocomplete="new-password"
              :error-messages="showFieldErrors ? errors : []"
              :hint="$t('userSignUpStep3.passwordHint')"
              :label="$t('userSignUpStep3.password')"
              persistent-hint
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </validation-provider>
        </v-container>
      </v-main>
      <v-expand-transition>
        <v-footer v-if="!invalid" app>
          <v-container>
            <v-btn
              block
              class="my-3"
              color="primary"
              large
              rounded
              type="submit"
            >
              {{ $t("userSignUpStep3.action") }}
            </v-btn>
          </v-container>
        </v-footer>
      </v-expand-transition>
    </validation-observer>
  </div>
</template>

<script>
import { MiddlerAPIError } from "@/services/middler/errors.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapStores } from "pinia";
import { useDeviceStore } from "@/stores/device";
import GoBackBtn from "@/components/buttons/GoBackBtn.vue";
import websocket from "@/websocket";

export default {
  components: {
    GoBackBtn,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      password: "",
      showFieldErrors: false,
      showPassword: false,
    };
  },
  computed: {
    ...mapStores(useDeviceStore),
  },
  mounted() {
    if (!this.$route.query.phoneNumber || !this.$route.query.verificationCode) {
      this.$router.push({ name: "userSignUpStep1" });
    }
  },
  methods: {
    async onSubmit() {
      this.showFieldErrors = true;
      const registered = await this.register();
      if (registered) {
        await this.deviceStore.fetchOrCreate();
        websocket.reconnect();
        this.$router.push({
          name: "enableNotifications",
          query: {
            redirect: this.$route.query.redirect,
          },
        });
      }
    },
    async register() {
      try {
        await this.$userRepo.register(
          this.firstName,
          this.lastName,
          this.$route.query.phoneNumber,
          this.$route.query.verificationCode,
          this.password
        );
        return true;
      } catch (error) {
        if (error instanceof MiddlerAPIError) {
          if (error.data.verificationCode) {
            this.$router.push({
              name: "userSignUpStep2",
              query: {
                phoneNumber: this.$route.query.phoneNumber,
                redirect: this.$route.query.redirect,
              },
            });
          }
          // Set errors for each field
          this.$refs.form.setErrors(error.data);
          return false;
        } else {
          throw error;
        }
      }
    },
  },
};
</script>
