<template>
  <div>
    <v-dialog v-if="isWebPlatform" v-model="modal" width="290px">
      <template #activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          :error-messages="errors"
          :label="label"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          :value="value"
          v-on="on"
        />
      </template>
      <v-time-picker
        :allowed-minutes="allowedMinutes"
        format="24hr"
        full-width
        :min="min"
        scrollable
        :value="value"
        @input="
          $emit('input', $event);
          modal = false;
        "
      />
    </v-dialog>
    <v-text-field
      v-else
      :error-messages="errors"
      :label="label"
      type="time"
      :value="value"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import { VInput } from "vuetify/lib";

export default {
  extends: VInput,
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    min: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      allowedMinutes: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
      modal: false,
    };
  },
  computed: {
    isWebPlatform() {
      return Capacitor.getPlatform() === "web";
    },
  },
};
</script>
