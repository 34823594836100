import _snakeCase from "lodash/snakeCase";
import axios from "axios";
import camelcaseKeysDeep from "camelcase-keys-deep";
import decamelizeKeysDeep from "decamelize-keys-deep";

const client = axios.create({
  baseURL: process.env.VUE_APP_MIDDLER_API_URL,
  transformRequest: axios.defaults.transformRequest.concat((data) => {
    if (data instanceof FormData) {
      for (const [key, value] of data.entries()) {
        data.delete(key);
        data.append(_snakeCase(key), value);
      }
      return data;
    }
    return data ? JSON.stringify(decamelizeKeysDeep(JSON.parse(data))) : data;
  }),
  transformResponse: axios.defaults.transformResponse.concat((data) => {
    return camelcaseKeysDeep(data);
  }),
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  withCredentials: true,
  withXSRFToken: true,
});

client.interceptors.response.use(
  (response) => {
    const csrfToken = response.headers["x-csrftoken"];
    if (csrfToken) {
      client.defaults.headers["X-CSRFToken"] = csrfToken;
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default client;
