import { Model } from "pinia-orm";
import Place from "@/models/Place";
import User from "@/models/User";
import Vote from "@/models/Vote";

export default class Poll extends Model {
  static entity = "polls";
  static fields() {
    return {
      id: this.string(null),
      created: this.string(null),
      userId: this.string(null),
      user: this.belongsTo(User, "userId"),
      tripId: this.string(null),
      placeId: this.string(null),
      place: this.belongsTo(Place, "placeId"),
      votes: this.hasMany(Vote, "pollId"),
    };
  }
}
