import { Model } from "pinia-orm";
import MessageStatus from "@/models/MessageStatus";
import User from "@/models/User";

export default class Message extends Model {
  static entity = "messages";
  static fields() {
    return {
      id: this.string(null),
      created: this.string(null),
      text: this.string(""),
      tripId: this.string(null),
      senderId: this.string(null),
      sender: this.belongsTo(User, "senderId"),
      isSystem: this.boolean(false),
      statuses: this.hasMany(MessageStatus, "messageId"),
      data: this.attr(null),
    };
  }
}
