<template>
  <div v-if="trip" class="fill-height">
    <message-snackbar :trip-id="tripId" />
    <v-app-bar
      app
      color="primary"
      dark
      :extension-height="$vuetify.breakpoint.mobile ? 48 : 64"
      flat
    >
      <go-back-btn exact />
      <v-spacer />
      <v-toolbar-title class="mr-8">{{ trip.name }}</v-toolbar-title>
      <v-spacer />
      <template v-if="hasJoined" #extension>
        <v-tabs
          background-color="primary"
          dark
          fixed-tabs
          :icons-and-text="!$vuetify.breakpoint.mobile"
          slider-color="secondary"
        >
          <v-tab exact :to="{ name: 'tripInfo', params: { tripId } }">
            <span v-if="!$vuetify.breakpoint.mobile" class="text-none mt-1">
              {{ $t("tripDetail.tabs.details") }}
            </span>
            <v-icon>mdi-information</v-icon>
          </v-tab>
          <v-tab exact :to="{ name: 'tripMessages', params: { tripId } }">
            <span v-if="!$vuetify.breakpoint.mobile" class="text-none mt-1">
              {{ $t("tripDetail.tabs.messages") }}
            </span>
            <v-badge
              v-if="unreadMessageCount"
              :content="unreadMessageCount"
              overlap
            >
              <v-icon>mdi-chat</v-icon>
            </v-badge>
            <v-icon v-else>mdi-chat</v-icon>
          </v-tab>
          <v-tab exact :to="placeTabRoute">
            <span v-if="!$vuetify.breakpoint.mobile" class="text-none mt-1">
              {{ $t("tripDetail.tabs.places") }}
            </span>
            <v-icon>mdi-map-marker-multiple</v-icon>
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <keep-alive include="TripMap">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useConfigStore } from "@/stores/config";
import GoBackBtn from "@/components/buttons/GoBackBtn.vue";
import MessageSnackbar from "@/components/MessageSnackbar.vue";

export default {
  components: {
    GoBackBtn,
    MessageSnackbar,
  },
  computed: {
    ...mapStores(useConfigStore),
    authTripUser() {
      return this.$tripUserRepo
        .where("tripId", this.tripId)
        .where("userId", this.$userRepo.authUser.id)
        .first();
    },
    hasJoined() {
      return this.authTripUser && this.authTripUser.joined;
    },
    trip() {
      return this.$tripRepo.find(this.tripId);
    },
    tripId() {
      return this.$route.params.tripId;
    },
    placeTabRoute() {
      if (this.authTripUser.lat) {
        return {
          name: this.configStore.placeViewName,
          params: { tripId: this.tripId },
        };
      } else {
        return {
          name: "tripUserEdit",
          params: { tripId: this.tripId, userId: this.$userRepo.authUser.id },
        };
      }
    },
    unreadMessageCount() {
      return this.$messageRepo.getUnreadMessageCount(
        this.tripId,
        this.$userRepo.authUser.id
      );
    },
  },
};
</script>
