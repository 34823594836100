import BaseService from "@/services/middler/BaseService";

export default class MessageService extends BaseService {
  async list(tripId) {
    const path = `trips/${tripId}/messages`;
    const response = await this.client.get(path);
    return response.data;
  }

  async create(tripId, data) {
    const path = `trips/${tripId}/messages`;
    const response = await this.client.post(path, data);
    return response.data;
  }

  async markReceived(tripId) {
    const path = `trips/${tripId}/messages/mark_received`;
    const response = await this.client.post(path);
    return response.data;
  }

  async markRead(tripId) {
    const path = `trips/${tripId}/messages/mark_read`;
    const response = await this.client.post(path);
    return response.data;
  }
}
