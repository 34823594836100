<template>
  <div>
    <v-btn
      v-for="priceRange in priceRanges"
      :key="priceRange"
      active-class="primary--text"
      class="mb-2 mr-2"
      :input-value="value.includes(priceRange)"
      rounded
      @click="onBtnClick(priceRange)"
    >
      {{ $t(`priceRanges.${priceRange}`) }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      priceRanges: ["cheap_eats", "mid-range", "fine_dining"],
    };
  },
  methods: {
    onChange(value) {
      this.$emit("input", value);
    },
    onBtnClick(priceRange) {
      if (this.value.includes(priceRange)) {
        this.$emit(
          "input",
          this.value.filter((v) => v !== priceRange)
        );
      } else {
        this.$emit("input", [...this.value, priceRange]);
      }
    },
  },
};
</script>
