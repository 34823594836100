import "./registerServiceWorker";
import "./vee-validate";
import * as Sentry from "@sentry/vue";
import {
  BusinessException,
  MiddlerAPIError,
  NetworkError,
  NotFound,
  TooManyRequests,
} from "@/services/middler/errors.js";
import { Capacitor } from "@capacitor/core";
import { PiniaVuePlugin, createPinia } from "pinia";
import { Preferences } from "@capacitor/preferences";
import { createORM } from "pinia-orm";
import { initializeApp } from "firebase/app";
import { markRaw } from "vue";
import { useRepo } from "pinia-orm";
import App from "./App.vue";
import DepartureAddressRepository from "@/repositories/DepartureAddressRepository";
import MessageRepository from "@/repositories/MessageRepository";
import PlaceRepository from "@/repositories/PlaceRepository";
import PollRepository from "@/repositories/PollRepository";
import TripRepository from "@/repositories/TripRepository";
import TripUserPlace from "@/models/TripUserPlace";
import TripUserRepository from "@/repositories/TripUserRepository";
import TripadvisorImage from "@/models/TripadvisorImage";
import UserRepository from "@/repositories/UserRepository";
import Vue from "vue";
import VueRouter from "vue-router";
import client from "@/services/middler/client.js";
import i18n from "./plugins/i18n";
import router from "./router";
import vuetify from "./plugins/vuetify";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
};

if (!Capacitor.isNativePlatform()) {
  initializeApp(firebaseConfig);
}

if (Capacitor.isNativePlatform()) {
  // Get the cookie from the native storage and set it in the document
  Preferences.get({ key: "cookie" }).then(({ value }) => {
    if (value) {
      document.cookie = value;
    }
  });
}

client.interceptors.response.use(
  (response) => {
    if (response.data.code) {
      throw new BusinessException(response.data.detail, response.data.code);
    }
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
        case 403:
          if (error.response.data.code === "not_authenticated") {
            localStorage.clear();
            router.push({
              name: "userAuth",
              query: router.history.pending
                ? { redirect: router.history.pending.fullPath }
                : {},
            });
          } else if (error.response.data.code === "permission_denied") {
            router.push({ name: "tripList" });
          } else {
            throw new MiddlerAPIError(
              error.response.statusText,
              error.response.data
            );
          }
          break;

        case 404:
          throw new NotFound();

        case 429:
          throw new TooManyRequests(
            error.response.data.detail,
            error.response.data.detail.match(/\b\d+\b/)[0]
          );

        default:
          throw new MiddlerAPIError(
            error.response.statusText,
            error.response.data
          );
      }
    } else {
      throw new NetworkError();
    }
    return Promise.reject(error);
  }
);

Vue.use(PiniaVuePlugin);
const pinia = createPinia().use(createORM());

Vue.prototype.$departureAddressRepo = useRepo(DepartureAddressRepository);
Vue.prototype.$messageRepo = useRepo(MessageRepository);
Vue.prototype.$placeRepo = useRepo(PlaceRepository);
Vue.prototype.$pollRepo = useRepo(PollRepository);
Vue.prototype.$tripadvisorImageRepo = useRepo(TripadvisorImage);
Vue.prototype.$tripRepo = useRepo(TripRepository);
Vue.prototype.$tripUserPlaceRepo = useRepo(TripUserPlace);
Vue.prototype.$tripUserRepo = useRepo(TripUserRepository);
Vue.prototype.$userRepo = useRepo(UserRepository);

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

Vue.use(VueRouter);

if (process.env.NODE_ENV === "production") {
  Vue.config.productionTip = false;
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    ignoreErrors: ["Request failed with status code 403"],
  });
}

new Vue({
  i18n,
  pinia,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
