import BaseService from "@/services/middler/BaseService";

export default class DeviceService extends BaseService {
  basename = "devices";

  async create(data) {
    const response = await this.client.post(this.basename, data);
    return response.data;
  }

  async retrieve(id, params) {
    const config = { params };
    const response = await this.client.get(`${this.basename}/${id}`, config);
    return response.data;
  }

  async partialUpdate(id, data) {
    const response = await this.client.patch(`${this.basename}/${id}`, data);
    return response.data;
  }
}
