<template>
  <v-btn
    v-if="trip.chosenPlaceId !== place.id"
    v-bind="$attrs"
    @click="choosePlace"
  >
    <span>{{ $t("choosePlaceBtn.chooseBtn.label") }}</span>
    <v-icon>mdi-check</v-icon>
  </v-btn>
  <v-btn v-else v-bind="$attrs" @click="cancelPlace">
    <span>{{ $t("choosePlaceBtn.cancelBtn.label") }}</span>
    <v-icon>mdi-close</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    place: {
      type: Object,
      required: true,
    },
    trip: {
      type: Object,
      required: true,
    },
  },
  methods: {
    choosePlace() {
      this.$tripRepo.update({
        id: this.trip.id,
        chosenPlaceId: this.place.id,
      });
    },
    cancelPlace() {
      this.$tripRepo.update({
        id: this.trip.id,
        chosenPlaceId: null,
      });
    },
  },
};
</script>
