<template>
  <v-dialog
    v-model="dialog"
    :width="$vuetify.breakpoint.mobile ? '80vw' : '20vw'"
  >
    <template #activator="{ on, attrs }">
      <slot v-bind="on" :attrs="attrs" name="activator"></slot>
    </template>
    <v-card class="rounded-lg">
      <v-form @submit.prevent="confirm">
        <v-card-text>{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn rounded text @click="cancel">
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn color="primary" rounded type="submit">{{
            action || $t("common.yes")
          }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
    },
  },
};
</script>
