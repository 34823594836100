<template>
  <v-menu>
    <template #activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon>mdi-translate</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        @click="changeLocale(item.code)"
      >
        <v-list-item-title>
          {{ item.flag }}&nbsp;&nbsp;{{ item.text }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [
        { text: "English", flag: "🇬🇧", code: "en" },
        { text: "Français", flag: "🇫🇷", code: "fr" },
      ],
    };
  },
  mounted() {
    this.changeLocale(this.value);
  },
  methods: {
    changeLocale(value) {
      this.$emit("input", value);
    },
  },
};
</script>
