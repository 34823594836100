<template>
  <v-btn icon rel="noreferrer" @click="open()">
    <v-img
      src="@/assets/images/Tripadvisor_Logo_dark-bg_circle-green_RGB.svg"
      width="36px"
    />
  </v-btn>
</template>

<script>
import { Browser } from "@capacitor/browser";

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    open() {
      Browser.open({ url: this.url });
    },
  },
};
</script>
