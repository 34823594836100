import { Model } from "pinia-orm";

export default class DepartureAddress extends Model {
  static entity = "departureAddresses";
  static fields() {
    return {
      id: this.string(null),
      name: this.string(""),
      address: this.string(""),
      lat: this.number(null),
      lng: this.number(null),
    };
  }
}
