import i18n from "@/plugins/i18n.js";

export const getTravelModes = () => ({
  transit: {
    name: i18n.t("travelModes.transit"),
    icon: "mdi-subway-variant",
    google: "transit",
  },
  bike: {
    name: i18n.t("travelModes.bike"),
    icon: "mdi-bicycle",
    google: "bicycling",
  },
  walk: {
    name: i18n.t("travelModes.walk"),
    icon: "mdi-walk",
    google: "walking",
  },
});
