import BaseService from "@/services/middler/BaseService";

export default class GISService extends BaseService {
  async geocode(query, language, proximity) {
    const path = "gis/geocode";
    const config = { params: { query, language, proximity } };
    const response = await this.client.get(path, config);
    return response.data;
  }

  async reverseGeocode(point, language) {
    const path = "gis/reverse_geocode";
    const config = { params: { point, language } };
    const response = await this.client.get(path, config);
    return response.data;
  }
}
