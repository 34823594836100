import { useRepo } from "pinia-orm";
import PlaceRepository from "@/repositories/PlaceRepository";
import TripRepository from "@/repositories/TripRepository";

const placeRepo = useRepo(PlaceRepository);
const tripRepo = useRepo(TripRepository);

async function refreshPlaces(tripId) {
  const trip = tripRepo.find(tripId);
  return Promise.all([
    placeRepo.list(tripId),
    placeRepo.search(
      tripId,
      trip.placeType,
      trip.cuisines,
      trip.priceRanges,
      trip.vehicleModes,
      trip.insideParis
    ),
  ]).then(() => {
    tripRepo.save({
      id: tripId,
      placesRefreshRequired: false,
    });
  });
}

export { refreshPlaces };
