<template>
  <img
    v-if="parentWidth"
    referrerpolicy="no-referrer"
    :src="src"
    style="object-fit: cover; width: 100%; height: 100%"
  />
</template>

<script>
export default {
  props: {
    filepath: {
      type: String,
      required: true,
    },
    quality: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      parentWidth: 0,
    };
  },
  computed: {
    src() {
      // Round up to the nearest 100 and set a minimum width of 500
      const imageWidth = Math.max(500, Math.ceil(this.parentWidth / 100) * 100);
      return `https://dynamic-media-cdn.tripadvisor.com/media/photo-${this.quality}${this.filepath}?w=${imageWidth}&h=-1&s=1`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.parentWidth = this.$el.parentNode.offsetWidth;
    });
  },
};
</script>
