export const getLanguages = () => ({
  en: {
    name: "English",
    flag: "🇬🇧",
  },
  fr: {
    name: "Français",
    flag: "🇫🇷",
  },
});
