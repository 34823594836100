<template>
  <v-main class="fill-height">
    <router-view v-if="'tripMapUserEdit' == $route.name" />
    <v-progress-circular
      v-if="loading"
      class="progress-overlay"
      color="primary"
      indeterminate
      :size="70"
    ></v-progress-circular>
    <mapbox-map
      v-if="trip"
      ref="mapboxMap"
      :bounds="bounds"
      :center="center"
      disable-rotation
      :hide-controls="!!selectedPlaceId"
      :language="deviceStore.language"
      :map-style="mapStyle"
      :max-zoom="18"
      :min-zoom="2"
      :zoom="12"
      @click="onMapClick"
      @load="onMapLoad"
    >
      <v-container>
        <v-btn
          class="mr-2"
          color="primary"
          rounded
          small
          style="z-index: 100"
          @click="onListBtnClick"
        >
          <v-icon left>mdi-view-list</v-icon>
          {{ $t("tripMap.list") }}
        </v-btn>
        <v-btn
          color="primary"
          rounded
          small
          style="z-index: 100"
          :to="{ name: 'placeFilters', params: { tripId: tripId } }"
        >
          <v-icon left>mdi-filter</v-icon>
          {{ $t("tripMap.filters") }}
        </v-btn>
      </v-container>
      <mapbox-marker
        v-for="(place, index) in places"
        :key="`place-${index}`"
        anchor="bottom"
        :color="getMarkerColor(place.id)"
        :lng-lat="[place.lng, place.lat]"
        @click="onPlaceMarkerClick(place.id)"
      >
        <place-marker
          :color="getMarkerColor(place.id)"
          :number="index + 1"
          :style="{
            'z-index': getPlaceMarkerZIndex(place.id, places.length, index),
          }"
        />;
      </mapbox-marker>
      <mapbox-marker
        v-for="(tripUser, index) in tripUsers"
        :key="`tripUser-${index}`"
        :color="$vuetify.theme.themes.light.primary"
        :draggable="isUserMarkerDraggable(tripUser.userId)"
        :lng-lat="
          tripUser.user.id === selectedUserId
            ? [selectedTripUserLng, selectedTripUserLat]
            : [tripUser.lng, tripUser.lat]
        "
        @click="onUserMarkerClick(tripUser.userId)"
        @dragend="onUserMarkerDragEnd(tripUser.userId, $event)"
      >
        <user-avatar
          :admin-badge="tripUser.isAdmin"
          :badge-icon="travelModes[tripUser.travelMode].icon"
          position="absolute"
          size="48"
          :user="tripUser.user"
        />
      </mapbox-marker>
      <v-expand-transition>
        <v-carousel
          v-if="selectedPlaceId"
          v-model="placeIndex"
          height="auto"
          hide-delimiters
          :show-arrows="!$vuetify.breakpoint.mobile"
          style="z-index: 30"
          @change="onCarouselChange"
        >
          <v-carousel-item
            v-for="(place, index) in places"
            :key="`place-carousel-${index}`"
          >
            <v-container>
              <place-card
                :index="index"
                :place-id="place.id"
                :trip-id="tripId"
              />
            </v-container>
          </v-carousel-item>
        </v-carousel>
      </v-expand-transition>
    </mapbox-map>
  </v-main>
</template>

<script>
import { getTravelModes } from "@/constants/travelModes";
import { mapStores } from "pinia";
import {
  lineString as turfLineString,
  multiPoint as turfMultiPoint,
} from "@turf/helpers";
import { useConfigStore } from "@/stores/config";
import { useDeviceStore } from "@/stores/device";
import { useLoadingStore } from "@/stores/loading";
import MapboxMap from "@/components/mapbox/MapboxMap.vue";
import MapboxMarker from "@/components/mapbox/MapboxMarker.vue";
import PlaceCard from "@/components/PlaceCard.vue";
import PlaceMarker from "@/components/PlaceMarker.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import _debounce from "lodash/debounce";
import turfBbox from "@turf/bbox";

export default {
  name: "TripMap",
  components: {
    MapboxMap,
    MapboxMarker,
    PlaceCard,
    PlaceMarker,
    UserAvatar,
  },
  data() {
    return {
      isMapLoaded: false,
      placeIndex: 0,
    };
  },
  computed: {
    ...mapStores(useConfigStore, useDeviceStore, useLoadingStore),
    bounds() {
      if (this.tripUsers.length < 2) return null;
      const coordinates = this.tripUsers.map((tripUser) => [
        tripUser.lng,
        tripUser.lat,
      ]);
      const line = turfLineString(coordinates);
      const bbox = turfBbox(line);
      return [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ];
    },
    center() {
      if (this.tripUsers.length !== 1) return [0, 0];
      const tripUser = this.tripUsers[0];
      return [tripUser.lng, tripUser.lat];
    },
    loading() {
      return (
        !this.isMapLoaded ||
        this.loadingStore["place.list"] ||
        this.loadingStore["place.search"]
      );
    },
    mapStyle() {
      return this.$vuetify.theme.dark
        ? process.env.VUE_APP_MAPBOX_STYLE_DARK
        : process.env.VUE_APP_MAPBOX_STYLE_LIGHT;
    },
    places() {
      const searchPlaces = this.trip.places.filter(
        (place) => place.isSearchResult
      );
      const searchPlaceIds = searchPlaces.map((place) => place.id);
      if (
        searchPlaceIds.includes(this.selectedPlaceId) ||
        !this.selectedPlaceId
      ) {
        return searchPlaces;
      } else {
        return this.selectedPlace ? [this.selectedPlace] : [];
      }
    },
    selectedPlace() {
      return this.$placeRepo.find(this.selectedPlaceId);
    },
    selectedPlaceId() {
      return this.$route.params.placeId;
    },
    selectedTripUser() {
      return this.$tripUserRepo
        .where("tripId", this.tripId)
        .where("userId", this.selectedUserId)
        .first();
    },
    selectedTripUserLat() {
      return this.$route.query.lat
        ? this.$route.query.lat
        : this.selectedTripUser.lat;
    },
    selectedTripUserLng() {
      return this.$route.query.lng
        ? this.$route.query.lng
        : this.selectedTripUser.lng;
    },
    selectedUserId() {
      return this.$route.params.userId;
    },
    travelModes: getTravelModes,
    trip() {
      return this.$tripRepo.with("places").find(this.tripId);
    },
    tripId() {
      return this.$route.params.tripId;
    },
    tripUsers() {
      return this.$tripUserRepo
        .where((tripUser) => tripUser.tripId == this.tripId && tripUser.lat)
        .with("user")
        .get();
    },
  },
  created() {
    this.debouncedGoToPreviousPlace = _debounce(this.goToPreviousPlace, 200, {
      leading: true,
      trailing: false,
    });
    this.debouncedGoToNextPlace = _debounce(this.goToNextPlace, 200, {
      leading: true,
      trailing: false,
    });
  },
  activated() {
    window.addEventListener("keydown", this.onKeyDown);

    // Force the map to resize to fill the space left by other views footer
    if (this.$refs.mapboxMap) {
      this.$refs.mapboxMap.map.resize();
    }
  },
  deactivated() {
    window.removeEventListener("keydown", this.onKeyDown);
  },
  methods: {
    easeTo(lat, lng) {
      this.$refs.mapboxMap.map.easeTo({
        center: [lng, lat],
      });
    },
    getMarkerColor(placeId) {
      return this.selectedPlaceId === placeId
        ? this.$vuetify.theme.themes.light.secondary
        : this.$vuetify.theme.themes.light.primary;
    },
    getPlaceMarkerZIndex(placeId, placesLength, index) {
      return this.selectedPlaceId === placeId ? 29 : placesLength - index;
    },
    goToPreviousPlace() {
      if (this.places.length < 2) return;
      this.placeIndex =
        (this.placeIndex - 1 + this.places.length) % this.places.length;
      this.onCarouselChange(this.placeIndex);
    },
    goToNextPlace() {
      if (this.places.length < 2) return;
      this.placeIndex = (this.placeIndex + 1) % this.places.length;
      this.onCarouselChange(this.placeIndex);
    },
    async onUserMarkerDragEnd(userId, lngLat) {
      this.$router.push({
        name: "tripMapUserEdit",
        params: { tripId: this.tripId, userId },
        query: {
          lat: lngLat.lat,
          lng: lngLat.lng,
        },
      });
    },
    onCarouselChange(index) {
      const placeId = this.places[index].id;
      this.$router.push({
        name: "tripMapPlace",
        params: { tripId: this.tripId, placeId },
      });
    },
    onMapClick() {
      if (this.selectedPlaceId) {
        this.$router.push({
          name: "tripMap",
          params: { tripId: this.tripId },
        });
      }
    },
    onMapLoad() {
      this.isMapLoaded = true;
      this.$watch(
        "selectedPlace",
        () => {
          // As the map runs in the background, we need to prevent other views from triggering this
          if (this.$route.name !== "tripMapPlace") return;

          if (this.selectedPlace) {
            this.easeTo(this.selectedPlace.lat, this.selectedPlace.lng);
            this.placeIndex = this.places.findIndex(
              (place) => place.id === this.selectedPlace.id
            );
          } else {
            this.recenter();
          }
        },
        { immediate: true }
      );
      this.$watch(
        "selectedTripUser",
        () => {
          if (this.selectedTripUser) {
            if (this.$route.query.address) return;
            this.easeTo(this.selectedTripUser.lat, this.selectedTripUser.lng);
          }
        },
        { immediate: true }
      );
      this.$placeRepo.piniaStore().$onAction(({ name, after }) => {
        after(() => {
          if (name === "save") this.recenter();
        });
      });
    },
    onListBtnClick() {
      this.configStore.placeViewName = "tripPlaces";
      this.$router.push({
        name: "tripPlaces",
        params: { tripId: this.tripId },
      });
    },
    onPlaceMarkerClick(placeId) {
      if (this.selectedPlaceId !== placeId) {
        this.$router.push({
          name: "tripMapPlace",
          params: { tripId: this.tripId, placeId },
        });
        this.placeIndex = this.places.findIndex(
          (place) => place.id === placeId
        );
      }
    },
    onUserMarkerClick(userId) {
      if (userId === this.$userRepo.authUser.id) {
        this.$router.push({
          name: "tripMapUserEdit",
          params: { tripId: this.tripId, userId },
        });
      }
    },
    isUserMarkerDraggable(userId) {
      return userId === this.$userRepo.authUser.id;
    },
    onKeyDown(event) {
      switch (event.key) {
        case "ArrowLeft":
          this.debouncedGoToPreviousPlace();
          break;

        case "ArrowRight":
          this.debouncedGoToNextPlace();
          break;
      }
    },
    recenter() {
      if (!this.$refs.mapboxMap) return;

      const coords = [];
      this.tripUsers.forEach((tripUser) => {
        if (tripUser.lat && tripUser.lng) {
          coords.push([tripUser.lng, tripUser.lat]);
        }
      });
      this.places.forEach((place) => {
        if (place.lat && place.lng) {
          coords.push([place.lng, place.lat]);
        }
      });
      const bbox = turfBbox(turfMultiPoint(coords));
      this.$refs.mapboxMap.map.fitBounds(bbox, { padding: 50 });
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
}
.v-carousel {
  position: fixed;
  bottom: 0px;
}
</style>
