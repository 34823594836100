<template>
  <div>
    <v-app-bar app color="transparent" flat>
      <go-back-btn :query="{ redirect: $route.query.redirect }" />
    </v-app-bar>
    <validation-observer
      ref="form"
      v-slot="{ invalid }"
      tag="form"
      @submit.prevent="onSubmit"
    >
      <v-main>
        <v-container>
          <div class="mb-5 text-h5">
            {{
              $t("userResetPasswordStep2.title", {
                phoneNumber: nationalPhoneNumber,
              })
            }}
          </div>
          <v-scroll-y-transition>
            <v-alert v-if="formErrorMessage" dense type="error">{{
              formErrorMessage
            }}</v-alert>
          </v-scroll-y-transition>
          <validation-provider rules="required|digits:6">
            <v-otp-input
              v-model="verificationCode"
              length="6"
              :style="{
                'max-width': $vuetify.breakpoint.thresholds.xs + 'px',
              }"
              type="number"
            />
            <p class="mt-2">
              {{ $t("userResetPasswordStep2.codeNotReceived") }}
              <v-btn
                class="ml-2"
                :disabled="resendCountdown > 0"
                outlined
                small
                @click="sendCode"
              >
                {{ $t("userResetPasswordStep2.resend") }}
                <span v-if="resendCountdown">({{ resendCountdown }})</span>
              </v-btn>
            </p>
          </validation-provider>
        </v-container>
      </v-main>
      <v-expand-transition>
        <v-footer v-if="!invalid" app>
          <v-container>
            <v-btn
              block
              class="my-3"
              color="primary"
              large
              rounded
              type="submit"
            >
              {{ $t("userResetPasswordStep2.action") }}
            </v-btn>
          </v-container>
        </v-footer>
      </v-expand-transition>
    </validation-observer>
  </div>
</template>

<script>
import { MiddlerAPIError } from "../services/middler/errors";
import { TooManyRequests } from "@/services/middler/errors.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { parsePhoneNumber } from "awesome-phonenumber";
import GoBackBtn from "@/components/buttons/GoBackBtn.vue";
import UserService from "@/services/middler/UserService";

const userService = new UserService();

export default {
  components: {
    GoBackBtn,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      formErrorMessage: null,
      phoneNumber: this.$route.query.phoneNumber,
      resendCountdown: 0,
      verificationCode: "",
    };
  },
  computed: {
    nationalPhoneNumber() {
      const pn = parsePhoneNumber(this.phoneNumber);
      return pn.number.national;
    },
  },
  mounted() {
    this.sendCode();
  },
  methods: {
    async sendCode() {
      this.verificationCode = "";
      try {
        await userService.sendPhoneNumberVerificationCode(this.phoneNumber);
        this.resendCountdown = 10;
      } catch (error) {
        if (error instanceof TooManyRequests) {
          this.resendCountdown = error.timeout;
        } else {
          throw error;
        }
      }
      const interval = setInterval(() => {
        this.resendCountdown--;
        if (this.resendCountdown === 0) {
          clearInterval(interval);
        }
      }, 1000);
    },
    async onSubmit() {
      try {
        await userService.verifyPhoneNumber(
          this.phoneNumber,
          this.verificationCode
        );
        this.$router.push({
          name: "userResetPasswordStep3",
          query: {
            phoneNumber: this.phoneNumber,
            verificationCode: this.verificationCode,
            redirect: this.$route.query.redirect,
          },
        });
      } catch (error) {
        if (error instanceof MiddlerAPIError) {
          if (error.data.code === "invalid_verification_code") {
            this.formErrorMessage = this.$t(
              "userResetPasswordStep2.invalidVerificationCode"
            );
            setTimeout(() => {
              this.formErrorMessage = null;
            }, 3000);
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      }
    },
  },
};
</script>
