<template>
  <div class="fill-height">
    <v-app-bar app flat />
    <v-main class="fill-height">
      <v-container
        class="d-flex flex-column justify-center text-center"
        fill-height
      >
        <div class="text-h5">{{ $t("userEnableNotifications.title") }}</div>
        <v-icon class="ma-10" color="primary" size="100">mdi-bell</v-icon>
        <div>{{ $t("userEnableNotifications.description") }}</div>
      </v-container>
    </v-main>
    <v-footer app>
      <v-container>
        <v-btn
          block
          class="my-3"
          color="primary"
          large
          :loading="notificationStore.loading"
          rounded
          @click="enable"
        >
          {{ $t("userEnableNotifications.action") }}
        </v-btn>
        <v-btn
          block
          class="mb-3"
          :disabled="notificationStore.loading"
          large
          rounded
          text
          @click="redirect"
        >
          {{ $t("userEnableNotifications.skip") }}
        </v-btn>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useNotificationStore } from "@/stores/notification";
import NotificationHandler from "@/utils/NotificationHandler";

export default {
  data() {
    return {
      notificationHandler: new NotificationHandler(),
    };
  },
  computed: {
    ...mapStores(useNotificationStore),
    isEnabled() {
      return this.notificationStore.isEnabled;
    },
    isSupported() {
      return this.notificationStore.isSupported;
    },
  },
  watch: {
    isEnabled(value) {
      if (value) this.redirect();
    },
    isSupported(value) {
      if (value === false) this.redirect();
    },
  },
  methods: {
    async enable() {
      await this.notificationHandler.toggle();
    },
    redirect() {
      this.$router.push(
        this.$route.query.redirect
          ? {
              path: this.$route.query.redirect,
            }
          : { name: "tripList" }
      );
    },
  },
};
</script>
