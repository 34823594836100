<template>
  <v-snackbar
    v-model="snackbar"
    bottom
    color="primary"
    text
    :timeout="trip.placesRefreshRequired ? -1 : 2000"
    @input="onSnackbarClose"
  >
    {{ currentSnackbarMessage }}
    <template v-if="trip.placesRefreshRequired" #action="{ attrs }">
      <v-btn color="primary" icon v-bind="attrs" @click="onRefreshBtnClick">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { refreshPlaces } from "@/utils/place";
import camelcaseKeysDeep from "camelcase-keys-deep";
import websocket from "@/websocket";

export default {
  props: {
    tripId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentSnackbarMessage: "",
      snackbar: false,
      snackbarMessages: [],
    };
  },
  computed: {
    trip() {
      return this.$tripRepo.find(this.tripId);
    },
  },
  created() {
    websocket.addEventListener("message", this.onWebsocketMessage);
  },
  beforeDestroy() {
    websocket.removeEventListener("message", this.onWebsocketMessage);
  },
  methods: {
    addToSnackbarMessages(message) {
      this.snackbarMessages.push(message);
      if (!this.snackbar) {
        this.showNextSnackbarMessage();
      }
    },
    onSnackbarClose() {
      setTimeout(() => {
        this.showNextSnackbarMessage();
      }, 500);
    },
    onWebsocketMessage(event) {
      const data = JSON.parse(event["data"]);
      const item = camelcaseKeysDeep(data.data);
      if (data.resource === "message" && data.action === "create") {
        if (
          item.isSystem &&
          item.tripId === this.tripId &&
          item.senderId !== this.$userRepo.authUser.id &&
          this.$route.name !== "tripMessages"
        ) {
          this.addToSnackbarMessages(item.text);
        }
      }
    },
    async onRefreshBtnClick() {
      await refreshPlaces(this.tripId);
      this.snackbar = false;
      this.onSnackbarClose();
    },
    showNextSnackbarMessage() {
      if (this.snackbarMessages.length > 0) {
        this.currentSnackbarMessage = this.snackbarMessages.shift();
        this.snackbar = true;
      }
    },
  },
};
</script>
