import { Device } from "@capacitor/device";
import { NotFound } from "@/services/middler/errors.js";
import { defineStore } from "pinia";
import DeviceService from "@/services/middler/DeviceService";
import _isEmpty from "lodash/isEmpty";
import _pickBy from "lodash/pickBy";

const deviceService = new DeviceService();

function getChanges(instance, state) {
  return _pickBy(instance, (value, key) => {
    return state[key] !== value;
  });
}

export const useDeviceStore = defineStore("device", {
  state: () => {
    return {
      id: null,
      darkMode: false,
      language: "en",
      timezone: "GMT",
      info: null,
      fcmToken: null,
    };
  },
  actions: {
    async getDeviceInfo() {
      const { identifier: id } = await Device.getId();
      const info = await Device.getInfo();
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.$patch({ id, info, timezone });
    },
    async fetchOrCreate() {
      await this.getDeviceInfo();
      try {
        const result = await deviceService.retrieve(this.id);
        this.$patch(result);
      } catch (error) {
        if (error instanceof NotFound) {
          const result = await deviceService.create(this.$state);
          this.$patch(result);
        } else {
          throw error;
        }
      }
    },
    async update(device) {
      const changes = getChanges(device, this.$state);
      if (!_isEmpty(changes)) {
        const result = await deviceService.partialUpdate(
          this.$state.id,
          changes
        );
        this.$patch(result);
      }
    },
    async save() {
      const result = await deviceService.partialUpdate(this.id, this.$state);
      this.$patch(result);
    },
  },
});
