<template>
  <v-card
    class="rounded-xl"
    :to="{ name: 'tripInfo', params: { tripId: trip.id } }"
  >
    <v-card-title v-if="trip.name">
      <place-type-icon color="primary" left :type="trip.placeType" />
      {{ trip.name }}
    </v-card-title>
    <v-card-subtitle>
      <place-type-icon
        v-if="!trip.name"
        color="primary"
        left
        :type="trip.placeType"
      />
      <time :arrivalDatetime="trip.arrivalDatetime">
        {{ formatDatetime(trip.arrivalDatetime) }}
      </time>
    </v-card-subtitle>
    <v-card-text>
      <user-avatar-list :trip-id="trip.id" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapStores } from "pinia";
import { useDeviceStore } from "@/stores/device";
import PlaceTypeIcon from "@/components/PlaceTypeIcon.vue";
import UserAvatarList from "@/components/UserAvatarList.vue";

export default {
  components: {
    PlaceTypeIcon,
    UserAvatarList,
  },
  props: {
    trip: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapStores(useDeviceStore),
  },
  methods: {
    formatDatetime(isoString) {
      const options = {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(isoString).toLocaleString(
        this.deviceStore.language,
        options
      );
    },
  },
};
</script>
