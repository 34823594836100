export default [
  "asian",
  "thai",
  "vietnamese",
  "japanese",
  "sushi",
  "french",
  "international",
  "european",
  "fusion",
  "bar",
  "pub",
  "moroccan",
  "mediterranean",
  "mexican",
  "spanish",
  "wine-bar",
  "cafe",
  "american",
  "seafood",
  "italian",
  "pizza",
  "fast-food",
  "chinese",
  "indian",
  "turkish",
  "lebanese",
  "middle-eastern",
  "african",
  "ethiopian",
  "healthy",
  "indonesian",
  "street-food",
  "greek",
  "contemporary",
  "gastropub",
  "dining-bars",
  "tibetan",
  "brew-pub",
  "hawaiian",
  "taiwanese",
  "russian",
  "central-asian",
  "deli",
  "grill",
  "korean",
  "cambodian",
  "northern-italian",
  "central-italian",
  "southern-italian",
  "caribbean",
  "cajun-creole",
  "soups",
  "japanese-fusion",
  "algerian",
  "steakhouse",
  "diner",
  "neapolitan",
  "campania",
  "brazilian",
  "british",
  "sri-lankan",
  "irish",
  "jamaican",
  "pakistani",
  "romana",
  "lazio",
  "nepali",
  "central-european",
  "south-american",
  "barbecue",
  "peruvian",
  "latin",
  "argentinean",
  "colombian",
  "central-american",
  "israeli",
  "tunisian",
  "scottish",
  "scandinavian",
  "norwegian",
  "venezuelan",
  "sicilian",
  "beer-restaurants",
  "portuguese",
  "bangladeshi",
  "sardinian",
  "eastern-european",
  "armenian",
  "chilean",
  "georgian",
  "tuscan",
  "beijing-cuisine",
  "japanese-sweets-parlour",
  "persian",
  "arabic",
  "assyrian",
  "swedish",
  "australian",
  "belgian",
  "kyoto-cuisine",
  "ecuadorean",
  "indigenous",
  "swiss",
  "malaysian",
  "cuban",
  "caucasian",
  "polish",
  "canadian",
  "apulian",
  "azerbaijani",
  "ukrainian",
  "ligurian",
  "native-american",
  "german",
  "fruit-parlours",
  "xinjiang",
  "egyptian",
  "southwestern",
  "fujian",
  "welsh",
  "catalan",
  "romanian",
  "romagna",
  "czech",
  "hungarian",
  "medicinal-foods",
  "afghan",
  "new-zealand",
  "filipino",
  "danish",
  "hokkaido-cuisine",
  "uzbek",
  "mongolian",
  "austrian",
  "kyushu-cuisine",
  "albanian",
  "calabrian",
  "yunnan",
  "nigerian",
  "singaporean",
  "dutch",
  "croatian",
  "bahamian",
  "emilian",
  "puerto-rican",
];
