import { digits, email, min, required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { localize } from "vee-validate";
import { parsePhoneNumber } from "awesome-phonenumber";
import en from "vee-validate/dist/locale/en.json";
import fr from "vee-validate/dist/locale/fr.json";

localize({
  en,
  fr,
});

extend("digits", digits);
extend("email", email);
extend("min", min);
extend("required", required);

extend("phoneNumber", {
  validate: (value) => {
    return parsePhoneNumber(value).valid;
  },
  message: "This is not a valid phone number.",
});
