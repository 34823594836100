import { parsePhoneNumber } from "awesome-phonenumber";

export default (contacts) => {
  const phoneNumbers = contacts
    .map((contact) => contact.phones)
    .flat()
    .map((phone) => (phone ? phone.number : null))
    .filter((phoneNumber) => phoneNumber);

  const uniquePhoneNumbers = [...new Set(phoneNumbers)];
  const parsedPhoneNumbers = uniquePhoneNumbers.map((phoneNumber) =>
    parsePhoneNumber(phoneNumber, { regionCode: "FR" })
  );
  const e164PhoneNumbers = parsedPhoneNumbers
    .filter((phoneNumber) => phoneNumber.valid)
    .map((phoneNumber) => phoneNumber.number.e164);

  return [...new Set(e164PhoneNumbers)];
};
