<template>
  <div>
    <v-app-bar app color="transparent" flat>
      <go-back-btn exact />
      <v-spacer />
      <v-toolbar-title class="mr-8">
        {{ $t("userSettingsLanguage.title") }}
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>
    <v-main>
      <v-container>
        <v-list flat>
          <v-list-item-group @change="onChange">
            <div v-for="(item, key, index) in languages" :key="key">
              <v-list-item :value="key">
                <v-list-item-icon>
                  <v-icon>{{ item.flag }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-icon v-if="key === device.language">
                  <v-icon>mdi-check</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider v-if="index < Object.keys(languages).length - 1" />
            </div>
          </v-list-item-group>
        </v-list>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { getLanguages } from "@/constants/languages";
import { useDeviceStore } from "@/stores/device";
import GoBackBtn from "@/components/buttons/GoBackBtn.vue";
import _clone from "lodash/clone";

export default {
  components: {
    GoBackBtn,
  },
  data: () => {
    const deviceStore = useDeviceStore();
    const device = _clone(deviceStore.$state);
    return {
      deviceStore,
      device,
    };
  },
  computed: {
    languages: getLanguages,
  },
  methods: {
    async onChange(travelMode) {
      this.device.language = travelMode;
      await this.deviceStore.update(this.device);
      this.$router.push({ name: "userSettings" });
    },
  },
};
</script>
