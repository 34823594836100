import { Model } from "pinia-orm";
import Place from "@/models/Place";
import Trip from "@/models/Trip";
import TripUserPlace from "@/models/TripUserPlace";
import User from "@/models/User";

export default class TripUser extends Model {
  static entity = "tripUsers";
  static fields() {
    return {
      id: this.string(null),
      userId: this.string(null),
      tripId: this.string(null),
      user: this.belongsTo(User, "userId"),
      trip: this.belongsTo(Trip, "tripId"),
      archived: this.boolean(false),
      isAdmin: this.boolean(false),
      address: this.string(""),
      lat: this.number(null),
      lng: this.number(null),
      travelMode: this.string("transit"),
      invited: this.string(null),
      joined: this.string(null),
      places: this.belongsToMany(Place, TripUserPlace, "tripUserId", "placeId"),
    };
  }
}
