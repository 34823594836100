import BaseService from "@/services/middler/BaseService";

export default class TripUserService extends BaseService {
  basename = "tripusers";

  async partialUpdate(id, data) {
    const response = await this.client.patch(`${this.basename}/${id}`, data);
    return response.data;
  }

  async delete(id) {
    await this.client.delete(`${this.basename}/${id}`);
  }
}
